exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-03-20-mirage-and-esp-32-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-03-20-mirage-and-esp32.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-03-20-mirage-and-esp-32-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-03-27-last-week-tonight-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-03-27-last-week-tonight.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-03-27-last-week-tonight-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-03-28-the-xtensa-architecture-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-03-28-the-xtensa-architecture.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-03-28-the-xtensa-architecture-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-04-27-compiling-and-linking-for-esp-32-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-04-27-compiling-and-linking-for-esp32.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-04-27-compiling-and-linking-for-esp-32-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-05-04-success-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-05-04-success.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-05-04-success-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-06-22-build-your-own-mirage-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-06-22-build-your-own-mirage.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-06-22-build-your-own-mirage-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-10-02-status-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-10-02-status.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-10-02-status-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-10-02-what-now-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2018-10-02-what-now.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2018-10-02-what-now-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2023-05-05-value-speculation-ocaml-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/articles/2023-05-05-value-speculation-ocaml.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-articles-2023-05-05-value-speculation-ocaml-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-amogu-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/projects/amogu.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-amogu-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-esp-32-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/projects/esp32/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-esp-32-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-mean-field-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/projects/mean-field/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-mean-field-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-mirage-4-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/projects/mirage-4/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-mirage-4-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-theoremkb-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/projects/theoremkb/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-theoremkb-index-mdx" */),
  "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-windos-mdx": () => import("./../../../src/pages/{mdx.frontmatter__variant}/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/lucas/projects/TheLortex.github.io/src/data/projects/windos.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-variant-mdx-frontmatter-slug-tsx-content-file-path-src-data-projects-windos-mdx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

